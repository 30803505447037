import React, { useEffect } from 'react';
import { arrayOf, func, node, oneOf, shape, string } from 'prop-types';

// Block components
import BlockDefault from './BlockDefault';
import BlockFooter from './BlockFooter';
import BlockSocialMediaLink from './BlockSocialMediaLink';
import useVisibility from './useVisibility';

///////////////////////////////////////////
// Mapping of block types and components //
///////////////////////////////////////////

const defaultBlockComponents = {
  defaultBlock: { component: BlockDefault },
  footerBlock: { component: BlockFooter },
  socialMediaLink: { component: BlockSocialMediaLink },
};

////////////////////
// Blocks builder //
////////////////////

const BlockBuilder = props => {
  const { blocks, sectionId, options, setCurrentIndex, ...otherProps } = props;

  // Extract block & field component mappings from props
  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const { blockComponents, fieldComponents } = options || {};
  const blockOptionsMaybe = fieldComponents ? { options: { fieldComponents } } : {};

  // If there's no block, we can't render the correct block component
  if (!blocks || blocks.length === 0) {
    return null;
  }

  // Selection of Block components
  // Combine component-mapping from props together with the default one:
  const components = { ...defaultBlockComponents, ...blockComponents };

  return (
    <>
      {blocks.map((block, index) => {
        const config = components[block.blockType];
        const Block = config?.component;
        const blockId = block.blockId || `${sectionId}-block-${index + 1}`;

        // Use visibility tracking hook
        const [isVisible, visibilityRef] = useVisibility({ threshold: 0.5 });

        useEffect(() => {
          if (isVisible) {
            setCurrentIndex?.(index)
          }
        }, [isVisible, blockId]);

        if (Block) {
          const component = (
            <div ref={visibilityRef} key={`${blockId}_i${index}`}>
              <Block
                {...block}
                blockId={blockId}
                {...blockOptionsMaybe}
                {...otherProps}
              />
            </div>
          );
          const link = getLink(block);
          if (link) {
            return (
              <a href={link} key={`${blockId}_ai${index}`}>
                {component}
              </a>
            );
          }
          return component;
        } else {
          // If the block type is unknown, the app can't know what to render
          console.warn(`Unknown block type (${block.blockType}) detected inside (${sectionId}).`);
          return null;
        }
      })}
    </>
  );
};

const propTypeBlock = shape({
  blockId: string,
  blockName: string,
  blockType: oneOf(['defaultBlock', 'footerBlock', 'socialMediaLink']).isRequired,
  // Plus all kind of unknown fields.
  // BlockBuilder doesn't really need to care about those
});

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
  blockComponents: shape({ component: node }),
});

BlockBuilder.defaultProps = {
  blocks: [],
  options: null,
  responsiveImageSizes: null,
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
};

BlockBuilder.propTypes = {
  blocks: arrayOf(propTypeBlock),
  options: propTypeOption,
  responsiveImageSizes: string,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
};

export default BlockBuilder;

const dict = {
  Basketball: 'Basketball',
  Baseball: 'Baseball',
  'Field hockey': 'Field_Hockey',
  Soccer: 'Soccer',
  Football: 'Football',
  Golf: 'Golf',
  Gymnastics: 'Gymnastics',
  Hockey: 'Hockey',
  Lacrosse: 'Lacrosse',
  Softball: 'Softball',
  Swimming: 'Swimming',
  Tennis: 'Tennis',
  'Track and Field': 'trackandfield',
  Volleyball: 'Volleyball',
};

const getLink = (block) => {
  if (["featured-sports-landing-page", "featured-sports-row"].includes(block.blockId)) {
    return getSport(block.blockName);
  } else if (block.blockId === 'coach-user' && block.callToAction.content) {
    return `/l/${block.callToAction.content}`
  }
  return "";
}

const getSport = (key) => {
  const value = dict[key]
  return value ? `/s?pub_Sport=has_all%3A${value}` : '/s'
}
