import React from 'react';
import styles from './SectionCoaches.module.css';

const DotNavigation = ({ totalItems, currentIndex }) => {
  const maxDots = 5;
  const isOverflow = totalItems > maxDots;

  // Calculate the sliding window start index based on `currentIndex`
  const start = Math.min(Math.max(0, currentIndex - Math.floor(maxDots / 2)), totalItems - maxDots);

  // Calculate the horizontal shift for sliding effect
  const offset = start * -20; // Assume each dot (including margin) is 20px wide

  return (
    <div className={styles.dotWrapper} style={{ width: isOverflow ? '100px' : 'auto' }}>
      <div
        className={styles.dotContainer}
        style={{
          transform: isOverflow ? `translateX(${offset}px)` : 'none',
          justifyContent: isOverflow ? 'center' : 'flex-start',
        }}
      >
        {Array.from({ length: totalItems }, (_, dotIndex) => (
          <div
            key={dotIndex}
            className={`${styles.dot} ${currentIndex === dotIndex ? styles.activeDot : ''}`}
          />
        ))}
      </div>
    </div>
  );
};

export default DotNavigation;
